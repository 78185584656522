import { graphql, Link } from 'gatsby'
import React, { FC } from 'react'
import styled from 'styled-components'
import HeadingTwo from '../components/HeadingTwo'
import ProfileImage, { ProfileName } from '../components/ProfileImage'
import Layout from '../components/layout/Layout'
import { StaffDetailQuery } from '../generated/types'
import { colors, fontSize, spacing } from '../styles/variables'

const Container = styled.div`
  min-height: 100vh;

  padding-top: ${spacing.size08};
  padding-bottom: ${spacing.size08};

  background-color: ${colors.white};

  & > .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: ${spacing.size09};

    & > .image {
      @media screen and (max-width: 500px) {
        width: 100%;

        margin-left: ${spacing.size05};
        margin-right: ${spacing.size05};

        &::before {
          display: none;
        }
      }
    }
  }

  & > .text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    padding-left: ${spacing.size08};
    padding-right: ${spacing.size08};

    & > .heading {
      margin-bottom: ${spacing.size07};

      text-align: center;
    }

    & > .text {
      max-width: 700px;

      line-height: 1.4;

      & li {
        margin-bottom: ${spacing.size02};
        list-style: circle;
      }

      & > h2 {
        margin-bottom: ${spacing.size04};
      }

      & > p,
      & > ul,
      & > blockquote {
        margin-bottom: ${spacing.size06};
      }

      & > blockquote {
        font-weight: bold;
      }
    }
  }

  @media screen and (max-width: 600px) {
    & > .text-wrapper {
      padding-left: ${spacing.size05};
      padding-right: ${spacing.size05};

      & > .heading {
        font-size: ${fontSize.size07};
      }
    }
  }
`

interface Props {
  data: StaffDetailQuery
}

const StaffTemplate: FC<Props> = (props) => {
  const { frontmatter, html } = props.data!.markdownRemark!
  const { photo, title } = frontmatter!

  return (
    <Layout>
      <Container>
        <div className="image-wrapper">
          {photo && <ProfileImage className="image" index={1} name={photo as ProfileName} />}
        </div>
        <div className="text-wrapper">
          <HeadingTwo className="heading" color={colors.main}>
            {title}
          </HeadingTwo>
          <div className="text" dangerouslySetInnerHTML={{ __html: html || '' }} />
          <Link to="/">Zpět</Link>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query StaffDetail($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        path
        title
        photo
      }
    }
  }
`

export default StaffTemplate
